import { useParams, Link } from "react-router-dom"

export default function UserHistoryYear() {





    const { id } = useParams()
    return (
        <>
            <Link to={'/history'} className="text-accent"> &lt;- Návrat</Link>
            <div className="w-full flex justify-center items-center">
                <h1 className=" text-4xl text-accent"> História roka {id}</h1>
                <div className="flex ">

                </div>
            </div>
            <h1>UserHistoryYear</h1>
            <h2>{id}</h2>
        </>
    )
}