import { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { useAuthContext } from "../../hooks/useAuthContext";
import { format } from "date-fns"
import sk from "date-fns/locale/sk"
import { useParams } from "react-router-dom";
import GameScores from "../../components/GameScores";
const EditGame = () => {
    const { id } = useParams()
    const { user } = useAuthContext();

    const [goals, setGoals] = useState([]);
    const [penalties, setPenalties] = useState([])
    const [interventions, setInterventions] = useState([])

    const [fix, setFix] = useState(false);
    const [game, setGame] = useState();

    const [players, setPlayers] = useState([])

    const [isPending, setIsPending] = useState(false);


    const [gk_a, setgk_a] = useState()
    const [gk_b, setgk_b] = useState()

    const [edit, setEdit] = useState(false)
    const [fields, setFields] = useState([])

    const [nmField, setNmField] = useState()
    const [nmDateTime, setNmDateTime] = useState()

    const [bestPlayer, setBestPlayer] = useState(0)

    //get all match data
    useEffect(() => {

        const getPlayers = async (t1, t2) => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/player/match?t1=${t1}&t2=${t2}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setPlayers(json)
                let test = json.filter(x => x.team_id === t1).find(x => x.role === 'gk')
                if (test) {
                    setgk_a(test.ID)
                }
                test = json.filter(x => x.team_id === t2).find(x => x.role === 'gk')
                if (test) {
                    setgk_b(test.ID)
                }
            } else {
                alert(json.error)
            }
        }

        const getGameStats = async (game) => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/stats/game/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setGoals(json.goals)
                let temp = game
                temp.team_a_goals = json.goals.filter(x => x.team_id === temp.team_a_id).length
                temp.team_b_goals = json.goals.filter(x => x.team_id === temp.team_b_id).length
                console.log();
                setGame(temp)
                setPenalties(json.penalties)
                setInterventions(json.interventions)
            } else {
                alert(json.error)
            }
        }

        const getGame = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
            if (response.ok) {
                setGame(json[0])
                getPlayers(json[0].team_a_id, json[0].team_b_id);
                getGameStats(json[0])
                setNmField(json[0].field)
                setNmDateTime(format(new Date(json[0].time), "y-M-d'T'H:mm", { locale: sk }))
                setGameProgress(json[0].progress)
                setBestPlayer(json[0].best_player)
            } else {
                alert(json.error)
            }
        }

        getGame()

    }, [])



    const getFields = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/field`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            console.log(json);
            setFields(json)
        } else {
            alert(json.error)
        }
    }


    useEffect(() => {
        if (fields.length < 1) {
            getFields()
        }

    }, [edit])


    const changeMatchStatus = async (status) => {
        let team_a_goals, team_b_goals, winner;
        if (status === 'finished') {
            team_a_goals = game.team_a_goals
            team_b_goals = game.team_b_goals
            if (team_a_goals > team_b_goals) {
                winner = game.team_a_id
            } else if (team_a_goals < team_b_goals) {
                winner = game.team_b_id
            } else {
                winner = -1
            }
        } else {
            team_a_goals = game.team_a_goals
            team_b_goals = game.team_b_goals
            winner = 0;
        }
        setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/status/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ status, team_a_goals, team_b_goals, winner }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            setGame({ ...game, status })


        } else {

            alert(json.error)
        }
        setIsPending(false)
    }

    const cancel = () => {
        setEdit(false)
        setNmField(game.field)
        setNmDateTime(game.time.replace('Z', ''))
    }

    const [gameProgress, setGameProgress] = useState()

    const changeProgress = async () => {
        setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/progress/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ progress: gameProgress }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            setGame({ ...game, progress: gameProgress })


        } else {

            alert(json.error)
        }
        setIsPending(false)
    }

    const updateParams = async () => {
        setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/params/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ time: nmDateTime, field: nmField }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            setGame(json[0])
        } else {

            alert(json.error)
        }
        setIsPending(false)
    }

    const saveBestPlayer = async () => {
        setIsPending(true)
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/player/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ best_player: bestPlayer }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()
        if (response.ok) {
            let temp = { ...game }
            temp.best_player = bestPlayer
            setGame(temp)
        } else {

            alert(json.error)
        }
        setIsPending(false)
    }

    return (
        <div className="flex">
            <AdminNavbar />
            <div className="p-2">
                {game &&
                    <div className="w-screen md:w-[80vw] z-50">
                        <div className="w-[99vw] md:w-[80vw] fixed flex justify-between">
                            <div className=" w-min whitespace-nowrap text-2xl bg-borders px-3 py-1 rounded-md  ">
                                <h2>{game.status === 'scheduled' && 'Plánovaný'}</h2>
                                <h2>{game.status === 'live' && 'Prebieha'}</h2>
                                <h2>{game.status === 'finished' && 'Ukončený'}</h2></div>
                            <div className=" w-min whitespace-nowrap text-2xl bg-borders px-3 py-1 rounded-md">{game.team_a_name} {game.team_a_goals} : {game.team_b_goals} {game.team_b_name}</div>
                            <div className=" w-min whitespace-nowrap text-2xl bg-borders px-3 py-1 rounded-md ">
                                <h2>{game.progress === '1.' && '1. Polčas'}</h2>
                                <h2>{game.progress === '2.' && '2. Polčas'}</h2>
                                <h2>{game.progress === 'OT' && 'Predĺženie'}</h2>
                                <h2>{game.progress === 'SN' && 'Samostatné nájazdy'}</h2>
                            </div>
                        </div>
                        <div className="flex justify-around pt-12 w-screen  md:w-[85vw] z-10">
                            {game.status === 'scheduled' &&
                                <div className="my-10">
                                    <div className="btn text-2xl !py-3" onClick={e => changeMatchStatus('live')}>Spustiť zápas</div>

                                </div>
                            }
                            {game.status === 'live' &&
                                <div className="flex items-center justify-around w-full">
                                    <div className="text-accent border border-accent my-2 text-2xl py-1 px-2 cursor-pointer" onClick={e => changeMatchStatus('scheduled')}>Plánovať</div>
                                    <div className="flex flex-col my-5 justify-around">
                                        <select className=" px-3 py-2 text-lg" value={gameProgress} onChange={x => setGameProgress(x.target.value)}>
                                            <option value={"1."}>1. Polčas</option>
                                            <option value={"2."}>2. Polčas</option>
                                            <option value={"OT"}>Predĺženie</option>
                                            <option value={"SN"}>Samostatné nájazdy</option>
                                        </select>
                                        <button disabled={gameProgress === game.progress} className="btn text-xl" onClick={changeProgress}>Uložiť</button>
                                    </div>
                                    <div className="btn text-2xl !py-1" onClick={e => { changeMatchStatus('finished') }}>Ukončiť</div>
                                </div>
                            }
                            {game.status === 'finished' &&
                                <div className="flex items-center justify-around w-full">
                                    <div className="text-accent border border-accent my-2 text-2xl py-1 px-2 cursor-pointer" onClick={e => changeMatchStatus('live')}>Zmeniť na prebiehajúci</div>
                                    <div className="flex flex-col my-5 justify-around">
                                        <select className=" px-3 py-2 text-lg" value={bestPlayer} onChange={x => setBestPlayer(x.target.value)}>
                                            <option value={null}>- Najlepší hráč -</option>
                                            {players.sort((a, b) => a.number - b.number).map(e => (
                                                (<option value={e.ID}>#{e.number} {e.first_name.charAt(0)}. {e.last_name} ({e.role}) - {e.team_id === game.team_a_id ? game.team_a_name : game.team_b_name}</option>)
                                            ))}

                                        </select>
                                        <button disabled={game.best_player === bestPlayer || bestPlayer === null} className="btn text-xl" onClick={saveBestPlayer}>Uložiť</button>
                                        {!fix && <div className="mt-5 border-2 font-bold cursor-pointer   border-accent text-accent py-2 px-3 text-center" onClick={e => setFix(true)}>Opraviť chybu</div>}
                                        {fix && <div className="mt-5 border-2 font-bold cursor-pointer   border-accent text-accent py-2 px-3 text-center" onClick={e => setFix(false)}>Dokončiť opravu</div>}
                                    </div>
                                    <div className="text-accent border border-accent my-2 text-2xl py-1 px-2 cursor-pointer" onClick={e => { changeMatchStatus('scheduled') }}>Zmeniť na plánovaný</div>
                                </div>
                            }

                        </div>



                        <div className="w-screen md:w-[80vw] flex justify-around mx-auto">

                            {(game.status === 'live' || fix) &&
                                <div className="flex justify-between w-full">
                                    <GameScores team_name={game.team_a_name} isPending={isPending} setIsPending={setIsPending} thisTeam={'a'} game={game} setGame={setGame} goals={goals} setGoals={setGoals} penalties={penalties} setPenalties={setPenalties} interventions={interventions} setInterventions={setInterventions} user={user} myGK={gk_a} otherGK={gk_b} setMyGK={setgk_a} players={players.filter(x => x.team_id === game.team_a_id)} myTeamID={game.team_a_id} />
                                    <GameScores team_name={game.team_b_name} isPending={isPending} setIsPending={setIsPending} thisTeam={'b'} game={game} setGame={setGame} goals={goals} setGoals={setGoals} penalties={penalties} setPenalties={setPenalties} interventions={interventions} setInterventions={setInterventions} user={user} myGK={gk_b} otherGK={gk_a} setMyGK={setgk_b} players={players.filter(x => x.team_id === game.team_b_id)} myTeamID={game.team_b_id} />

                                </div>

                            }
                        </div>
                        <div className="w-screen md:w-[80vw] text-center flex justify-center pt-12 ">
                            {user.rights.includes("y") && <div>
                                {(!edit && game.status !== 'finished') && <button className="text-accent border border-accent my-2 text-2xl py-2 px-4 " onClick={e => setEdit(true)}> Upraviť Čas a Miesto</button>}
                                {edit &&
                                    <div className="text-xl mx-8 ">
                                        <p className="text-center text-xl">Čas a miesto</p>
                                        <div className="flex flex-col child:my-2">
                                            <input type="datetime-local" value={nmDateTime} onChange={e => setNmDateTime(e.target.value)} />
                                            <select className="text-xl p-2 text-center" value={nmField} onChange={e => setNmField(parseInt(e.target.value))}>
                                                {fields.map(e => (
                                                    <option key={e.ID} className="text-xl" value={e.ID}>{e.field_name !== '-' && 'Ihrisko'} {e.field_name} </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex justify-between">
                                            <button className="text-accent border border-accent my-2 text-2xl py-2 px-4" onClick={cancel}>Zrušiť</button>
                                            <button disabled={nmField === game.field && nmDateTime === format(new Date(game.time), "y-M-d'T'H:mm", { locale: sk })} className="bg-accent text-white my-2 text-2xl py-2 px-4 cursor-pointer" onClick={updateParams}>Uložiť</button>
                                        </div>
                                    </div>}
                            </div>}
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default EditGame;    