import { Link } from "react-router-dom";
import { useYearContext } from "../hooks/useYearContext";
import SmallGame from "../components/SmallGame";
import { useEffect, useRef, useState } from "react";
import Image from "../components/Image";


const Home = () => {
    const { current } = useYearContext();






    const [games, setGames] = useState([])
    const [live, setLive] = useState(true)
    const [time, setTime] = useState(0)
    //const timeRef = useRef(time);
    const getTime = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/changes`)
        const json = await response.json()
        if (response.ok) {
            // console.log(timeRef);
            // console.log(time, new Date(json[0].time).valueOf(), time < new Date(json[0].time).valueOf());
            if (time < new Date(json[0].time).valueOf()) {
                getGames();
                setTime(new Date(json[0].time).valueOf())
                console.log("A");
            } else {
                console.log("B");
            }
        } else {
            alert(json.error)
        }
    }
    const getGames = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/live?year=${current.year}`)
        const json = await response.json()
        if (response.ok) {

            if (json.length === 0) {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/upcoming?year=${current.year}`)
                const json = await response.json()
                if (response.ok) {
                    setGames(json)
                    setLive(false)
                } else {
                    alert(json.error)
                }
            } else {
                setLive(true)
                setGames(json)
            }
        } else {
            alert(json.error)
        }
    }

    useEffect(() => {
        if (current && time === 0) {
            getTime()
        }
        let interval = setInterval(() => {
            if (current) {
                getTime()
            }
        }, 30000)

        return () => {
            clearInterval(interval)
        }

    }, [current, time])


    // useEffect(() => {
    //     let count = 0
    //     let playerImg = document.getElementsByClassName("playersIMG")

    //     let interval = setInterval(() => {
    //         let lastCount = count
    //         count++;
    //         if (count == 3) {
    //             count = 0
    //         }
    //         if (playerImg[count] && playerImg[lastCount]) {
    //             playerImg[count].classList.add("opacity-100")
    //             playerImg[count].classList.remove("opacity-0")
    //             playerImg[lastCount].classList.add("opacity-0")
    //             playerImg[lastCount].classList.remove("opacity-100")
    //         }

    //     }, 4000)

    //     return () => {
    //         clearInterval(interval)
    //     }

    // }, [])



    return (
        <div className="">
            {/* {current && <div className="md:flex fixed top-0 left-0 w-screen h-screen items-center justify-around -z-10 ">
                <h1 className="md:hidden swomum text-7xl text-[#232323] mx-auto text-center mt-16 ">VHT {current.year}</h1>
                <div className="relative mt-3 mt:my-0 mx-auto md:mx-0 w-[50vw] md:w-auto ">
                    <Image src="/img/VHT_1.webp" className="w-[50vw] mx-auto md:w-[28vw] playersIMG duration-[2000ms] opacity-100 " />
                    <Image src="/img/VHT_2.webp" className="w-[42vw] mx-auto md:w-[22vw] playersIMG duration-[2000ms] top-0 absolute opacity-0 " />
                    <Image src="/img/VHT_3.webp" className="w-[50vw] mx-auto md:w-[28vw] playersIMG duration-[2000ms] top-0 absolute opacity-0 " />
                </div>
                {current !== undefined && <div className="">
                    {current.status === "planned" && <div className="flex flex-col justify-between h-[50vh] mb-12">
                        <h1 className="swomum text-8xl text-[#232323] desktop:text-[10rem]">VHT {current.year}</h1>
                        <h2 className=" text-8xl text-accent text-right">Začíname už <br /> 8. 11. 2024</h2>
                    </div>}
                    {(current.status === "ongoing") && <div className="md:flex flex-col justify-around mt-12 md:mt-0 h-[70vh] mb-12 md:relative -left-36">
                        <h1 className="hidden md:block swomum text-8xl text-[#232323] desktop:text-[10rem] pb-16">VHT {current.year}</h1>
                        {games.length > 0 && <div className="w-4/5 md:w-full mx-auto md:mx-0  ">
                            {live && <div className="bg-accent text-white text-center py-2 md:py-5">
                                <h2>Aktuálne hrané zápasy</h2>
                            </div>}
                            {!live && <div className="bg-white border border-accent text-accent text-center py-2 md:py-5">
                                <h2>Nasledujúce zápasy</h2>
                            </div>}
                            {games.map(e => (
                                <SmallGame key={e.ID} e={e} className={" bg-white "} />
                            ))}
                        </div>}
                    </div>}

                </div>}
            </div >} */}
            <div className="flex w-screen md:h-screen justify-center relative bg-black -z-10  ">
                <img src="\img\VHT_24texture.png" alt="" className=" w-screen object-cover fixed h-[40vh] md:h-screen top-0 left-0 z-10" />
                <img src="/img/main24.webp" alt="" className="fixed  z-10 w-screen object-contain h-[40vh] md:h-[80vh] md:mt-[5vh]  " />
                <div className="h-[40vh] md:h-[80vh]"></div>
                <div className="absolute bottom-0 left-0 w-screen h-[20vh] bg-gradient-to-b from-transparent to-black/50 md:to-black/90 z-20"></div>
            </div>
            <div className="w-screen bg-accent  z-40  ">
                <div class="logos py-7 md:py-16">
                    <div class="logos-slide w-max child:h-9 md:child:h-16 child:inline-block child:mx-6 md:child:mx-12 ">
                        <img src="/img/sponsors/CAMKE.webp" alt="logo Camke" />
                        <img src="/img/sponsors/DOMKA.webp" alt="logo Domka" />
                        <img src="/img/sponsors/KSK.webp" alt="logo KSK" />
                        <img src="/img/sponsors/MSVVM.webp" alt="logo MSVVM" />
                        <img src="/img/sponsors/NIVAM.webp" alt="logo NIVAM" />
                        <img src="/img/sponsors/PS.webp" alt="logo PS" />
                        <img src="/img/sponsors/SDB.webp" alt="logo SDB" />
                        <img src="/img/sponsors/Webex.webp" alt="logo Webex" />
                    </div>
                    <div class="logos-slide w-max child:h-10 md:child:h-16 child:inline-block child:mx-6 md:child:mx-12 ">
                        <img src="/img/sponsors/CAMKE.webp" alt="logo Camke" />
                        <img src="/img/sponsors/DOMKA.webp" alt="logo Domka" />
                        <img src="/img/sponsors/KSK.webp" alt="logo KSK" />
                        <img src="/img/sponsors/MSVVM.webp" alt="logo MSVVM" />
                        <img src="/img/sponsors/NIVAM.webp" alt="logo NIVAM" />
                        <img src="/img/sponsors/PS.webp" alt="logo PS" />
                        <img src="/img/sponsors/SDB.webp" alt="logo SDB" />
                        <img src="/img/sponsors/Webex.webp" alt="logo Webex" />
                    </div>
                </div>


            </div>
            {current !== undefined && <div className="w-screen relative bg-[url('VHT_24texture.png')] z-30 bg-black" >
                <div className="absolute top-0 left-0 w-screen h-[20vh] bg-gradient-to-t from-transparent via-black/60 to-black -z-20"></div>

                {(current.status === "ongoing") && <div className="md:flex flex-col justify-around md:w-[30vw] pt-12 md:mt-0 md:h-[70vh]   mx-auto ">
                    {games.length > 0 && <div className="w-4/5 md:w-full mx-auto md:mx-0 z-20 ">
                        {live && <div className="bg-accent text-white text-center py-2 md:py-5">
                            <h2>Aktuálne hrané zápasy</h2>
                        </div>}
                        {!live && <div className="bg-white border border-accent text-accent text-center py-2 md:py-5">
                            <h2>Nasledujúce zápasy</h2>
                        </div>}
                        {games.map(e => (
                            <SmallGame key={e.ID} e={e} className={" bg-white "} />
                        ))}
                    </div>}
                </div>}

            </div>}
            <div className="bg-[url('VHT_24texture.png')] py-32 bg-black">
                <div className="w-[80vw]  md:w-[60vw] mx-auto   text-white z-50  ">

                    <div className="md:w-[80%] md:child:text-3xl child:text-2xl child:leading-9 child:font-medium z-50 ">
                        <p className="mb-10">VHT je celoslovenský hokejbalový turnaj určený pre hráčov v katégórii U15. Každoročne sa v metropole východu stretávajú chlapci zo saleziánskych stredísk z celého Slovenska, aby si zmerali svoje sily na ihrisku.</p>

                        <p>Ide o udalosť, ktorá oslovuje nielen hráčov, ale aj ich rodiny a priateľov. Na turnaji vládne vskutku unikátna atmosféra, ktorá vytvára nezabudnuteľné chvíle nielen na ihrisku, ale aj mimo neho.</p>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center relative pb-24 bg-[url('VHT_24texture.png')] bg-black ">
                <div className="absolute bottom-0 left-0 w-screen h-screen bg-gradient-to-b from-transparent via-black/80 to-black  "></div>
                <Image src={"/img/VHT24_Poster.webp"} className={"w-4/5 md:w-2/5 z-40"} />
            </div>

        </div >
    );
}

export default Home;