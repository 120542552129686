const UserHistory = () => {


    return (
        <div className=" w-[90vw] md:w-[70vw] xl:w-[46vw] mx-auto mt-12 flex flex-wrap justify-center">

            <div className="bg-white border border-borders p-10 mb-5 w-1/5 mx-3 h-min min-w-[200px] ">
                <h1 className="text-2xl font-semibold mb-2">2022</h1>
                <a target="_blank" rel="noreferrer" className="block py-1" href="https://vht.limix.eu/zapasy">Výsledky</a>
                <a target="_blank" rel="noreferrer" className="block py-1" href="https://vht.limix.eu/stats">Štatistiky</a>
                <a target="_blank" rel="noreferrer" className="block py-1" href="https://vht.limix.eu/zapasy">Zápasy</a>
                <a target="_blank" rel="noreferrer" className="block py-1" href="https://vht.limix.eu/timy">Tímy</a>
            </div>


        </div>
    );
}

export default UserHistory;