import { Link, useLocation } from "react-router-dom";
import Logo from "./Logo";
import { useEffect, useState } from "react";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

const Navbar = () => {
    const [show, setShow] = useState(false)
    const { logout } = useLogout()
    const { user } = useAuthContext()
    console.log(window.location.pathname);
    const location = useLocation()
    const [currnetPath, setCurrnetPath] = useState(location.pathname)

    useEffect(() => {
        console.log(location);

        setCurrnetPath(location.pathname)
    }, [location])

    const [selected, setSelected] = useState(1)

    const changeMenu = () => {

        let temp = !show

        let elem = document.getElementById("menu")
        let control = document.getElementsByClassName("controls")
        console.log(elem);
        setShow(!show)
        if (user) {

        }
        if (temp) {
            console.log(elem)
            elem.classList.add("h-screen")
            elem.classList.remove("child:opacity-0")
            elem.classList.remove("child:hidden")
            elem.classList.remove("h-0")

            control[1].classList.remove("opacity-0")

            control[0].classList.add("opacity-0")
        } else {
            elem.classList.remove("h-screen")
            elem.classList.add("h-0")
            elem.classList.add("child:opacity-0")
            elem.classList.add("child:hidden")

            control[0].classList.remove("opacity-0")
            control[1].classList.add("opacity-0")
        }

    }

    return (
        <nav className={`h-16 w-screen ${(currnetPath === '/tv' || currnetPath === '/live') ? " hidden" : " block"}`}>
            <div className={`fixed top-0 h-16  z-50 w-screen ${currnetPath === '/' ? " bg-[#fafafa] text-black " : " bg-web-gray text-white "}`}>
                <div className="hidden lg:flex h-16  w-[65vw] mx-auto  justify-around  items-center relative ">
                    <Link className="mx-10 absolute -left-5 cursor-pointer " to={""}>
                        <Logo className={`hover:text-accent duration-300${currnetPath === '/' ? "  text-black " : "  text-white "} `} />
                    </Link>

                    <div className="w-functional hidden lg:block">
                        <nav className="flex w-[40vw] justify-around child-hover:text-accent child:duration-300 child:px-1">
                            <Link to={"/games"}>Zápasy</Link>
                            <Link to={"/leaderboard"}>Tabuľka</Link>
                            <Link to={"/stats"}>Štatistiky</Link>
                            <Link to={"/schedule"}>Program</Link>
                            <Link to={"/history"}>História</Link>
                            <Link to={"/gallery"}>Foto</Link>
                            <Link to={"/rules"}>Pravidlá</Link>
                        </nav>
                    </div>
                </div>
                <div className=" lg:hidden h-16 w-[80vw]  flex  justify-between  items-center mx-8 ">
                    <Link className="" to={""}>
                        <Logo className={`hover:text-accent duration-300  ${currnetPath === '/' ? " text-black " : "  text-white "}`} />
                    </Link>
                    {user && <div className="text-lg text-accent">
                        Admin
                    </div>}
                    <div>
                        <img src="/img/burgir.svg" className={`absolute controls duration-300 ${currnetPath === '/' ? "  invert " : "  "}`} alt="" onClick={e => changeMenu()} />
                        <img src="/img/x.svg" className={`controls opacity-0 duration-300${currnetPath === '/' ? " invert " : "  "}`} alt="" onClick={e => changeMenu()} />
                    </div>


                </div>

                <div id="menu" className="menu lg:hidden w-screen fixed bg-web-gray duration-300 h-0 child:opacity-0 child:duration-300">
                    {user &&
                        <div className="flex justify-around my-3">
                            <div className={`text-2xl pb-1 ${selected === 0 ? "border-b-accent border-b-2 text-accent" : " text-white "}`} onClick={e => setSelected(0)} >Všetko</div>
                            <div className={`text-2xl pb-1 ${selected === 1 ? "border-b-accent border-b-2 text-accent" : " text-white "}`} onClick={e => setSelected(1)} >Admin</div>

                        </div>
                    }
                    {(selected === 0 || !user) && <nav className={`flex flex-col h-[80%] child:text-2xl justify-around items-center child-hover:text-accent child:duration-300 text-white`} onClick={changeMenu}>
                        <Link to={"/"}>Domov</Link>
                        <Link to={"/games"}>Zápasy</Link>
                        <Link to={"/leaderboard"}>Tabuľka</Link>
                        <Link to={"/stats"}>Štatistiky</Link>
                        <Link to={"/schedule"}>Program</Link>
                        <Link to={"/history"}>História</Link>
                        <Link to={"/gallery"}>Foto</Link>
                        <Link to={"/rules"}>Pravidlá</Link>
                        {!user && <Link to={"/login"}>
                            <img className="w-8" src="/img/admin.svg" alt="" />
                        </Link>}
                    </nav>}
                    {(selected === 1 && user) && <nav className={`flex flex-col h-[80%] child:text-2xl justify-around items-center child-hover:text-accent child:duration-300  text-white`} onClick={changeMenu}>
                        <Link to={'/admin/'} className="">Prehľad</Link>
                        {user.rights.includes('a') && <Link to={'/admin/users'} className="">Používatelia</Link>}
                        {user.rights.includes('y') && <Link to={'/admin/tournament'} className="">Turnaj</Link>}
                        {user.rights.includes('g') && <Link to={'/admin/games'} className="">Zápasy</Link>}
                        {user.rights.includes('t') && <Link to={'/admin/teams'} className="">Tímy</Link>}
                        {user.rights.includes('f') && <Link to={'/admin/gallery'} className="">Galéria</Link>}
                        <div className="text-center">

                            <button className="btn mx-auto rounded " onClick={logout}>Odhlásiť</button>
                        </div>
                    </nav>}
                </div>
            </div>


        </nav>
    );
}

export default Navbar;