import { useEffect, useState } from "react";
import Image from "../../components/Image";
import Loader from "../../components/Loader";
const UserGallery = () => {
    const [loaded, setLoaded] = useState(true)
    const [gallery, setGallery] = useState([])
    useEffect(() => {
        const getGallery = async () => {
            const response = await fetch(`${process.env.REACT_APP_PATH}/api/gallery`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            const json = await response.json()
            if (response.ok) {
                setGallery(json.sort((a, b) => b.year - a.year))
            } else {
                alert(json.error)
            }
        }

        getGallery()

    }, [])


    return (
        <div className="w-[90vw] md:w-[70vw] xl:w-[52vw] mx-auto mt-12 flex flex-wrap justify-center">
            {gallery.map(e => (
                <div>
                    {loaded && <a href={e.link} target="_blank" rel="noreferrer" className="text-center flex flex-col justify-center items-center text-white w-[80vw] h-[80vw] md:w-[13vw] md:h-[13vw] min-w-[200px] cursor-pointer hover:text-accent duration-200 m-4 ">

                        <Image src="/img/gallery.webp" className="absolute w-[80vw] md:w-[13vw] grayscale duration-200 hover:grayscale-0" setLoaded={setLoaded} />
                        <h1 className="z-10 text-7xl">{e.heading}</h1>
                        <h1 className="z-10 text-3xl relative -top-3 !text-white">{e.subheading}</h1>

                    </a>}
                    {!loaded && <Loader />}
                </div>

            ))}
            {gallery.length < 1 &&
                < div className="text-xl" > Zatiaľ žiadne fotky :(</div >
            }


        </div >
    );
}

export default UserGallery;