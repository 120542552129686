import { useYearContext } from "../../hooks/useYearContext";
import SmallGame from "../../components/SmallGameTV";
import { useEffect, useState } from "react";
import Image from "../../components/Image";



export default function Live() {

    const { current } = useYearContext();
    const [games, setGames] = useState([])
    const [live, setLive] = useState(true)
    const [time, setTime] = useState(0)

    const getTime = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/changes`)
        const json = await response.json()
        if (response.ok) {
            if (time < new Date(json[0].time).valueOf()) {
                getGames();
                setTime(new Date(json[0].time).valueOf())
                console.log("A");
            } else {
                console.log("B");
            }
        } else {
            alert(json.error)
        }
    }
    const getGames = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/live?year=${current.year}`)
        const json = await response.json()
        if (response.ok) {
            setLive(json.length !== 0)

            const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/upcoming?year=${current.year}`)
            const json2 = await response.json()
            if (response.ok) {
                setGames([...json, ...json2])

            } else {
                alert(json.error)
            }
        } else {
            alert(json.error)
        }
    }

    useEffect(() => {
        if (current && time === 0) {
            getTime()
        }
        let interval = setInterval(() => {
            if (current) {
                getTime()
                getGames()
            }
        }, 15000)

        return () => {
            clearInterval(interval)
        }

    }, [current, time])


    return (
        <div className="bg-black w-screen h-screen">


            {current !== undefined && <div className=" absolute bg-black left-10 top-10 " >

                {(current.status === "ongoing" && live) && <div className="md:flex flex-col justify-around w-[20vw]  mx-auto ">
                    {games.length > 0 && <div className="w-4/5 md:w-full mx-auto md:mx-0 z-20 ">
                        {games.filter((e, i) => e.status === 'live' && i === 0).map(props => (
                            <div className={`bg-white flex border border-borders justify-between  py-3 px-3 cursor-pointer  ${props.status === "scheduled" ? "" : "bg-white"} ${props.className}`}>
                                <div className=" flex items-center w-full ">
                                    <div className="">
                                        <p className={`text-2xl pb-2 ${(props.team_a_goals < props.team_b_goals && props.status === "finished") ? `text-dark-text` : ``}`} >{props.team_a_name ? props.team_a_name : props.code_a_name}</p>
                                        <p className={`text-2xl pt-2 ${(props.team_b_goals < props.team_a_goals && props.status === "finished") ? `text-dark-text` : ``}`}>{props.team_b_name ? props.team_b_name : props.code_b_name}</p>
                                    </div>
                                </div>
                                {props.status === "live" && <div className="border-l-2 text-center    ">
                                    <p className="mx-6 pb-2 text-3xl  ">{props.team_a_goals}</p>
                                    <p className="mx-6 pt-2 text-3xl ">{props.team_b_goals}</p>
                                </div>}

                            </div >
                        ))}
                    </div>}
                </div>}

            </div>}




        </div >
    );

}