import { useYearContext } from "../../hooks/useYearContext";
import SmallGameTV from "../../components/SmallGameTV";
import { useEffect, useState } from "react";
import Image from "../../components/Image";



export default function TV() {

    const { current } = useYearContext();
    const [games, setGames] = useState([])
    const [live, setLive] = useState(true)
    const [time, setTime] = useState(0)

    const getTime = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/changes`)
        const json = await response.json()
        if (response.ok) {
            if (time < new Date(json[0].time).valueOf()) {
                getGames();
                setTime(new Date(json[0].time).valueOf())
                console.log("A");
            } else {
                console.log("B");
            }
        } else {
            alert(json.error)
        }
    }
    const getGames = async () => {
        const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/live?year=${current.year}`)
        const json = await response.json()
        if (response.ok) {
            setLive(json.length !== 0)

            const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/upcoming?year=${current.year}`)
            const json2 = await response.json()
            if (response.ok) {
                setGames([...json, ...json2])

            } else {
                alert(json.error)
            }
        } else {
            alert(json.error)
        }
    }

    useEffect(() => {
        if (current && time === 0) {
            getTime()
        }
        let interval = setInterval(() => {
            if (current) {
                getTime()
                getGames()
            }
        }, 15000)

        return () => {
            clearInterval(interval)
        }

    }, [current, time])


    return (
        <div className="">
            <img src="\img\VHT_24texture.png" alt="" className="absolute w-screen h-screen object-cover bg-black    top-0 left-0 -z-10" />


            <div className="flex w-screen md:h-screen justify-center relative  -z-10  ">
                <div className="w-1/2 flex flex-col justify-center items-center">
                    <img src="/img/main24.webp" alt="" className=" w-[45vw] z-10  object-contain   " />
                    <p className="text-accent text-6xl font-semibold    mt-8 underline">vht.3horky.sk</p>
                </div>

                {current !== undefined && <div className="w-1/2 relative h-screen flex flex-col justify-around  z-30 " >

                    {(current.status === "ongoing" && live) && <div className="md:flex flex-col justify-around md:w-[30vw]   mx-auto ">
                        {games.length > 0 && <div className="w-4/5 md:w-full mx-auto md:mx-0 z-20 ">
                            {games.filter(e => e.status === 'live').length > 0 && <div className="bg-accent text-white text-center py-2 md:py-3">
                                <h2 className="font-medium text-3xl">Aktuálne hrané zápasy</h2>
                            </div>}
                            {games.filter(e => e.status === 'live').map(e => (
                                <SmallGameTV key={e.ID} e={e} className={"  "} />
                            ))}
                        </div>}
                    </div>}

                    {(current.status === "ongoing") && <div className="md:flex flex-col justify-around md:w-[30vw]  mx-auto ">
                        {games.length > 0 && <div className="w-4/5 md:w-full mx-auto md:mx-0 z-20 ">

                            {games.filter(e => e.status === 'scheduled').length > 0 && <div className=" border border-accent text-accent text-center py-2 md:py-3">
                                <h2 className="font-medium text-3xl">Nasledujúce zápasy</h2>
                            </div>}
                            {games.filter(e => e.status === 'scheduled').map(e => (
                                <SmallGameTV key={e.ID} e={e} className={"  "} />
                            ))}
                        </div>}
                    </div>}

                </div>}
            </div>




        </div >
    );

}