import { useEffect, useState } from "react";
import { useYearContext } from "../../hooks/useYearContext";
import SmallGame from "../../components/SmallGame";
import Loader from "../../components/Loader";
import SmallPlayoff from "../../components/SmallPlayoff";

const UserGames = () => {
    const { current } = useYearContext();

    const [games, setGames] = useState([])
    const [fields, setFields] = useState([])
    const [playoffs, setPlayoffs] = useState([])

    //const [count, setCount] = useState(0)

    useEffect(() => {
        if (current) {
            const getGames = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/game?year=${current.year}`)
                const json = await response.json()
                if (response.ok) {
                    setGames(json)
                } else {
                    alert(json.error)
                }
            }

            const getPlayoffs = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/game/playoff?year=${current.year}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                const json = await response.json()
                if (response.ok) {
                    console.log(json);
                    setPlayoffs(json)
                } else {
                    alert(json.error)
                }
            }



            const getFields = async () => {
                const response = await fetch(`${process.env.REACT_APP_PATH}/api/field`)
                const json = await response.json()
                if (response.ok) {
                    setFields(json)
                } else {
                    alert(json.error)
                }
            }

            getGames()
            getFields()
            getPlayoffs();
        }

    }, [current])

    return (
        <div className=" ">
            {(fields.length > 0 && playoffs.length > 0) && <div className="hidden md:block mt-8">
                {(games.length > 0 && fields.length > 0 && playoffs.filter(x => x.text !== "group").length > 0) &&
                    <div className="w-[80vw] mx-auto flex flex-col mb-8">
                        <div className="w-full mx-auto flex">
                            <div className="flex flex-col justify-around w-1/3 items-center" >
                                <h1 className="text-dark-text">Štvrťfinále</h1>
                            </div>
                            <div className="flex flex-col justify-around w-1/3 items-center" >
                                <h1 className="text-dark-text">Semifinále</h1>
                            </div>
                            <div className="flex flex-col justify-around w-1/3 items-center" >
                                <h1 className="text-dark-text">Finále</h1>
                            </div>
                        </div>
                        <div className="w-full mx-auto flex">
                            <div className="flex flex-col justify-around w-1/3 items-center" >
                                {games.filter(x => x.text === "QF1")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "QF1")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "QF1")[0] !== undefined && games.filter(x => x.text === "QF1")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "QF1")[0]} className={"w-[90%] my-3  "} />}


                                {games.filter(x => x.text === "QF2")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "QF2")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "QF2")[0] !== undefined && games.filter(x => x.text === "QF2")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "QF2")[0]} className={"w-[90%] my-3  "} />}


                                {games.filter(x => x.text === "QF3")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "QF3")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "QF3")[0] !== undefined && games.filter(x => x.text === "QF3")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "QF3")[0]} className={"w-[90%] my-3  "} />}


                                {games.filter(x => x.text === "QF4")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "QF4")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "QF4")[0] !== undefined && games.filter(x => x.text === "QF4")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "QF4")[0]} className={"w-[90%] my-3  "} />}


                            </div>
                            <div className="flex flex-col justify-around w-1/3 items-center" >
                                {games.filter(x => x.text === "SF1")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "SF1")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "SF1")[0] !== undefined && games.filter(x => x.text === "SF1")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "SF1")[0]} className={"w-[90%] my-3  "} />}

                                {games.filter(x => x.text === "SF2")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "SF2")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "SF2")[0] !== undefined && games.filter(x => x.text === "SF2")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "SF2")[0]} className={"w-[90%] my-3  "} />}
                            </div>
                            <div className="flex flex-col justify-around w-1/3 items-center" >
                                {games.filter(x => x.text === "Final")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "Final")[0]} className={"w-[90%] my-3  bg-white"} />}
                                {(playoffs.filter(x => x.text === "Final")[0] !== undefined && games.filter(x => x.text === "Final")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "Final")[0]} className={"w-[90%] my-3  "} />}
                            </div>
                        </div>

                    </div>
                }
                {(games.length > 0 && fields.length > 0 && games.filter(x => x.text === "group").length > 0) &&
                    <div className="w-[80vw] mx-auto flex mb-8">
                        {games.filter(x => x.text === "o7")[0] !== undefined &&
                            <div className="w-1/3 ">
                                <div className="w-[90%] mx-auto">
                                    <h1 className="text-dark-text text-center">O 7. miesto</h1>
                                    <SmallGame e={games.filter(x => x.text === "o7")[0]} className={"bg-white"} />
                                </div>
                            </div>
                        }
                        {(games.filter(x => x.text === "o7")[0] === undefined && playoffs.filter(x => x.text === "o7")[0] !== undefined) &&
                            <div className="w-1/3 ">
                                <div className="w-[90%] mx-auto">
                                    <h1 className="text-dark-text text-center">O 7. miesto</h1>
                                    <SmallPlayoff e={playoffs.filter(x => x.text === "o7")[0]} className={""} />
                                </div>
                            </div>
                        }

                        {(games.filter(x => x.text === "o7")[0] === undefined && playoffs.filter(x => x.text === "o7")[0] === undefined) &&
                            <div className="w-1/3 ">

                            </div>
                        }
                        {games.filter(x => x.text === "o5")[0] !== undefined &&
                            <div className="w-1/3">
                                <div className="w-[90%] mx-auto">
                                    <h1 className="text-dark-text text-center">O 5. miesto</h1>
                                    <SmallGame e={games.filter(x => x.text === "o5")[0]} className={"bg-white"} />
                                </div>
                            </div>
                        }
                        {(games.filter(x => x.text === "o5")[0] === undefined && playoffs.filter(x => x.text === "o5")[0] !== undefined) &&
                            <div className="w-1/3 ">
                                <div className="w-[90%] mx-auto">
                                    <h1 className="text-dark-text text-center">O 5. miesto</h1>
                                    <SmallPlayoff e={playoffs.filter(x => x.text === "o5")[0]} className={""} />
                                </div>
                            </div>
                        }
                        {(games.filter(x => x.text === "o5")[0] === undefined && playoffs.filter(x => x.text === "o5")[0] === undefined) &&
                            <div className="w-1/3 ">

                            </div>
                        }
                        {games.filter(x => x.text === "o3")[0] !== undefined &&
                            <div className="w-1/3">
                                <div className="w-[90%] mx-auto">
                                    <h1 className="text-dark-text text-center">O 3. miesto</h1>
                                    <SmallGame e={games.filter(x => x.text === "o3")[0]} className={"bg-white"} />
                                </div>

                            </div>
                        }
                        {(games.filter(x => x.text === "o3")[0] === undefined && playoffs.filter(x => x.text === "o3")[0] !== undefined) &&
                            <div className="w-1/3 ">
                                <div className="w-[90%] mx-auto">
                                    <h1 className="text-dark-text text-center">O 3. miesto</h1>
                                    <SmallPlayoff e={playoffs.filter(x => x.text === "o3")[0]} className={""} />
                                </div>
                            </div>
                        }

                    </div>
                }
            </div>}
            {fields.length > 0 && <div className="md:hidden w-[85vw] md:w-[70vw] xl:w-[46vw] mx-auto">
                {games.filter(x => x.text.includes("QF")).length > 0 && <div className="my-8">
                    <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Štvrťfinále</div>
                    <div className="md:flex">
                        <div className="md:w-1/2">
                            <div className="border border-borders  py-4 w-full text-center bg-white text-lg">Ihrisko {fields[1].field_name}</div>
                            <div className="">
                                {games.filter(x => x.field === fields[1].ID && x.text.includes("QF")).map((e, i) => (
                                    <SmallGame e={e} className={"min-w-[50%]"} />
                                ))}

                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="border border-borders  py-4 w-full text-center bg-white text-lg">Ihrisko {fields[2].field_name}</div>
                            <div className="">
                                {games.filter(x => x.field === fields[2].ID && x.text.includes("QF")).map((e, i) => (
                                    <SmallGame e={e} className={"min-w-[50%]"} />
                                ))}
                            </div>
                        </div>
                    </div>

                </div>}
                {(games.filter(x => x.text.includes("QF")).length === 0 && playoffs.filter(x => x.text.includes("QF")).length) && <div className="my-8">
                    <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Štvrťfinále</div>
                    <div className="md:flex">
                        <div className="md:w-1/2">
                            <div className="border border-borders  py-4 w-full text-center bg-white text-lg">Ihrisko {fields[1].field_name}</div>
                            <div className="">
                                {playoffs.filter(x => x.field === fields[1].ID && x.text.includes("QF") && x.text_b !== "").map((e, i) => (
                                    <SmallPlayoff e={e} className={"min-w-[50%]"} />
                                ))}

                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="border border-borders  py-4 w-full text-center bg-white text-lg">Ihrisko {fields[2].field_name}</div>
                            <div className="">
                                {playoffs.filter(x => x.field === fields[2].ID && x.text.includes("QF")).map((e, i) => (
                                    <SmallPlayoff e={e} className={"min-w-[50%]"} />
                                ))}
                            </div>
                        </div>
                    </div>

                </div>}
                {(games.filter(x => x.text.includes("SF")).length > 0 || playoffs.filter(x => x.text.includes("SF")).length > 0) && <div className="my-8">
                    <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Semifinále</div>
                    <div className="md:flex">
                        <div className="md:w-1/2">
                            <div className="">
                                {games.filter(x => x.text === "SF1")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "SF1")[0]} className={"min-w-[50%]"} />}
                                {(playoffs.filter(x => x.text === "SF1")[0] !== undefined && games.filter(x => x.text === "SF1")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "SF1")[0]} className={"min-w-[50%]"} />}

                                {games.filter(x => x.text === "SF2")[0] !== undefined && <SmallGame e={games.filter(x => x.text === "SF2")[0]} className={"min-w-[50%]"} />}
                                {(playoffs.filter(x => x.text === "SF2")[0] !== undefined && games.filter(x => x.text === "SF2")[0] === undefined) && <SmallPlayoff e={playoffs.filter(x => x.text === "SF2")[0]} className={"min-w-[50%]"} />}

                            </div>
                        </div>
                    </div>

                </div>}

                {games.filter(x => x.text.includes("Final")).length > 0 && <div className="my-8">
                    <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Finále</div>
                    <div className="md:flex">
                        <div className="md:w-1/2">
                            <div className="">
                                {games.filter(x => x.field === fields[1].ID && x.text.includes("Final")).map((e, i) => (
                                    <SmallGame e={e} className={"min-w-[50%]"} />
                                ))}
                            </div>
                        </div>
                    </div>

                </div>}
                {(games.filter(x => x.text.includes("Final")).length <= 0 && playoffs.filter(x => x.text === "Final")[0] !== undefined) && <div className="my-8">
                    <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Finále</div>
                    <div className="md:flex">
                        <div className="md:w-1/2">
                            <div className="">
                                {playoffs.filter(x => x.field === fields[1].ID && x.text.includes("Final")).map((e, i) => (
                                    <SmallPlayoff e={e} className={"min-w-[50%]"} />
                                ))}
                            </div>
                        </div>
                    </div>

                </div>}
                {(games.length > 0 && fields.length > 0 && playoffs.filter(x => x.text !== "group").length > 0) &&
                    <div className=" mx-auto flex flex-col mb-8">
                        {games.filter(x => x.text === "o3")[0] !== undefined &&
                            <div className="w-full my-2">
                                <div className=" w-full">
                                    <h1 className="text-dark-text text-center mb-1">O 3. miesto</h1>
                                    <SmallGame e={games.filter(x => x.text === "o3")[0]} className={"w-full bg-white"} />
                                </div>

                            </div>
                        }
                        {(games.filter(x => x.text === "o3")[0] === undefined && playoffs.filter(x => x.text === "o3")[0] !== undefined) &&
                            <div className="w-full my-2 ">
                                <div className=" mx-auto">
                                    <h1 className="text-dark-text text-center mb-1">O 3. miesto</h1>
                                    <SmallPlayoff e={playoffs.filter(x => x.text === "o3")[0]} className={""} />
                                </div>
                            </div>
                        }

                        {games.filter(x => x.text === "o5")[0] !== undefined &&
                            <div className="w-full my-2">
                                <div className=" mx-auto">
                                    <h1 className="text-dark-text text-center mb-1">O 5. miesto</h1>
                                    <SmallGame e={games.filter(x => x.text === "o5")[0]} className={"bg-white"} />
                                </div>
                            </div>
                        }
                        {(games.filter(x => x.text === "o5")[0] === undefined && playoffs.filter(x => x.text === "o5")[0] !== undefined) &&
                            <div className="w-full my-2 ">
                                <div className=" mx-auto">
                                    <h1 className="text-dark-text text-center mb-1">O 5. miesto</h1>
                                    <SmallPlayoff e={playoffs.filter(x => x.text === "o5")[0]} className={""} />
                                </div>
                            </div>
                        }
                        {games.filter(x => x.text === "o7")[0] !== undefined &&
                            <div className="w-full my-2 ">
                                <div className=" mx-auto">
                                    <h1 className="text-dark-text text-center mb-1">O 7. miesto</h1>
                                    <SmallGame e={games.filter(x => x.text === "o7")[0]} className={"bg-white"} />
                                </div>
                            </div>
                        }
                        {(games.filter(x => x.text === "o7")[0] === undefined && playoffs.filter(x => x.text === "o7")[0] !== undefined) &&
                            <div className="w-full my-2 ">
                                <div className=" mx-auto">
                                    <h1 className="text-dark-text text-center mb-1">O 7. miesto</h1>
                                    <SmallPlayoff e={playoffs.filter(x => x.text === "o7")[0]} className={""} />
                                </div>
                            </div>
                        }



                    </div>
                }
            </div>}

            <div className="w-[85vw] md:w-[70vw] xl:w-[46vw] mx-auto">
                {(games.length > 0 && fields.length > 0) && <div className="mt-12">
                    {games.filter(x => x.status === "live").length > 0 &&
                        <div>
                            <div className="bg-accent py-4 w-full text-center text-white text-lg">Aktuálne hrané zápasy</div>
                            <div className="md:flex flex-wrap">
                                {games.filter(x => x.status === "live").map((e, i) => (
                                    <SmallGame e={e} className={"min-w-[50%] bg-white"} />
                                ))}
                            </div>
                        </div>
                    }
                    {games.filter(x => x.status === "scheduled" && x.text === "group").length > 0 && <div className="my-8">
                        <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Skupinová časť</div>
                        <div className="md:flex">
                            <div className="md:w-1/2">
                                <div className="border border-borders  py-4 w-full text-center bg-white text-lg">Ihrisko {fields[1].field_name}</div>
                                <div className="">
                                    {games.filter(x => x.status === "scheduled" && x.field === fields[1].ID && x.text === "group").map((e, i) => (
                                        <SmallGame e={e} className={"min-w-[50%]"} />
                                    ))}
                                </div>
                            </div>
                            <div className="md:w-1/2">
                                <div className="border border-borders  py-4 w-full text-center bg-white text-lg">Ihrisko {fields[2].field_name}</div>
                                <div className="">
                                    {games.filter(x => x.status === "scheduled" && x.field === fields[2].ID && x.text === "group").map((e, i) => (
                                        <SmallGame e={e} className={"min-w-[50%]"} />
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>}

                    {games.filter(x => x.status === "finished" && x.text === "group").length > 0 && <div className="my-8">
                        <div className="border border-accent py-4 w-full text-center text-accent bg-white text-lg">Výsledky Skupinovej časti</div>
                        <div className="md:flex flex-wrap">
                            {games.sort((a, b) => new Date(b.time) - new Date(a.time)).filter(x => x.status === "finished" && x.text === "group").map((e, i) => (
                                <SmallGame e={e} className={"min-w-[50%] bg-white"} />
                            ))}
                        </div>
                    </div>}

                </div>}
                {!(games.length > 0 && fields.length > 0) &&
                    <Loader />
                }
            </div>




        </div>
    );
}

export default UserGames;