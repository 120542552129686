import { Link } from "react-router-dom";
import { format } from "date-fns"
import sk from "date-fns/locale/sk"

const SmallGame = (props, className) => {
    return (
        <Link to={`/games/${props.e.ID}`} className={`flex border border-borders justify-between p pr-8 py-3 md:py-5 cursor-pointer min-w-[50%] ${props.e.status === "scheduled" ? "" : "bg-white"} ${props.className}`}>
            <div className=" flex items-center justify-between w-full pr-8">
                <div className=" flex items-center">
                    <div className=" mr-8 relative -left-1 animate-pulse">
                        {props.e.status === "live" && <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M12 6L3 11.1962L3 0.803847L12 6Z" fill="#f04e3b" />
                        </svg>}
                    </div>
                    <div className="">
                        <p className={`pb-1 md:pb-3 ${(props.e.team_a_goals < props.e.team_b_goals && props.e.status === "finished") ? `text-dark-text` : ``}`} >{props.e.team_a_name ? props.e.team_a_name : props.e.code_a_name}</p>
                        <p className={`pt-1 md:pt-2 ${(props.e.team_b_goals < props.e.team_a_goals && props.e.status === "finished") ? `text-dark-text` : ``}`}>{props.e.team_b_name ? props.e.team_b_name : props.e.code_b_name}</p>
                    </div>
                </div>

                {<div>
                    {props.e.status === "finished" && <div className=" text-center">
                        <div className="flex relative items-center text-center">
                            {props.e.progress === "OT" && <p>pp</p>}
                            {props.e.progress === "SN" && <p>sn</p>}

                        </div>



                    </div>}

                </div>}
            </div>
            {props.e.status === "live" && <div className="border-l-2 text-center    ">
                <p className="pl-6 pt-1 pb-1 md:pb-3">{props.e.team_a_goals}</p>
                <p className="pl-6 pb-1 md:pt-2">{props.e.team_b_goals}</p>
            </div>}
            {props.e.status === "scheduled" && <div className="border-l-2 text-center flex flex-col justify-center text-dark-text w-20">
                <p className="pl-6">{format(new Date(props.e.time), 'eeee', { locale: sk }).charAt(0).toUpperCase() + format(new Date(props.e.time), "eeee", { locale: sk }).slice(1)}</p>
                <p className="pl-6">{props.e.time.substring(props.e.time.indexOf("T") + 1, props.e.time.indexOf("T") + 1 + 5)}</p>
            </div>}
            {props.e.status === "finished" && <div className="border-l-2 text-center">
                <div className=" pt-1 pb-3 flex relative items-center text-center">
                    {props.e.team_a_goals > props.e.team_b_goals &&
                        <svg className="absolute -left-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M12 6L3 11.1962L3 0.803847L12 6Z" fill="#000" />
                        </svg>
                    }
                    <p className={`pl-6 w-full ${props.e.team_a_goals >= props.e.team_b_goals ? `` : `text-dark-text`}`}>{props.e.team_a_goals}</p>
                </div>
                <div className=" pt-1 pb-3 flex relative items-center text-center">
                    {props.e.team_b_goals > props.e.team_a_goals &&
                        <svg className="absolute -left-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M12 6L3 11.1962L3 0.803847L12 6Z" fill="#000" />
                        </svg>
                    }
                    <p className={`pl-6 w-full ${props.e.team_b_goals >= props.e.team_a_goals ? `` : `text-dark-text`}`}>{props.e.team_b_goals}</p>
                </div>


            </div>}
        </Link >
    );
}

export default SmallGame;