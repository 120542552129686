export default function UserRules() {
    return (
        <div className="py-6 md:py-12 w-screen px-6 md:w-[60vw] mx-auto child:my-8 ">
            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">1.</mark></strong> Hrací čas:&nbsp;Hrajú sa 2 polčasy po 10 minút, tzv. čistý čas (pri prerušení hry sa čas&nbsp;zastavuje). V PLAY OFF, ak je po normálnom hracom čase stav nerozhodný, predlžuje sa&nbsp;jedenkrát 5 minút (1x5min.). V predĺžení sa rozhoduje gólom „rýchla smrť“. Ak sa&nbsp;nerozhodne v predĺžení nasledujú samostatné nájazdy, po 3 na každej strane. Ak nie je&nbsp;rozhodnuté, nasleduje po 1 nájazde na každej strane až dokiaľ sa nerozhodne.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent pb-1">2.</mark></strong> Pred začatím&nbsp;zápasu sa hráči postavia na svoje obranné modré čiary a pozdravia&nbsp;súpera. Na konci stretnutia si hráči podajú ruky v stredovom kruhu. Nepodanie ruky sa&nbsp;považuje za nešportové správanie.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">3.</mark></strong> Na súpiske mužstva môže byť maximálne 11 hráčov, z toho maximálne 2 brankári.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">4.</mark></strong> Na ihrisku&nbsp;musí mať každé mužstvo 4 hráčov a 1 brankára. Pri hre bez brankára 5&nbsp;hráčov. </p>



            <p><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent"><strong>5.</strong> </mark>Ak sa mužstvo&nbsp;nedostaví na zápas do 5 minút od stanoveného začiatku, tak sa zápas&nbsp;kontumuje. Kontumácia je 5 : 0 v prospech neprevineného družstva. Mužstvo je spôsobilé&nbsp;nastúpiť na zápas, ak má k dispozícii minimálne 4 hráčov a 1 brankára (resp. 5 hráčov a&nbsp;brankára).</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">6.</mark> </strong>Bodovací&nbsp;systém je nasledovný:&nbsp;</p>



            <p>- víťazstvo - 2b<br />- remíza - 1b<br />- prehra - 0b</p>



            <p>O poradí v skupine rozhoduje: počet bodov, vzájomný zápas, lepšie skóre (rozdiel v&nbsp;počte vsietených a inkasovaných gólov), vyšší počet strelených gólov, nižší počet&nbsp;inkasovaných gólov, žreb.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">7. </mark></strong>Hráč nesmie&nbsp;hrať so zlomenou hokejkou ani so špicom na konci čepele. Družstvo môže kedykoľvek počas prerušenia hry požiadať o posúdenie rozhodcov čepele súperovej hokejky. Ak bude nevhodná okamžite sa previnilému hráčovi uloží menší trest a s danou hokejkou nesmie pokračovať v hre. Ak bude čepeľ v poriadku uloží sa menší trest družstvu, ktoré požiadalo o premeranie.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">8. </mark></strong>Zakázané uvoľnenie:</p>



            <ol>
                <li>Pre potreby tohto pravidla stredná čiara rozdeľuje ihrisko na&nbsp;dve polovice.&nbsp;Pre posúdenie zakázaného uvoľnenia je rozhodujúce miesto posledného kontaktu s loptičkou toho družstva, ktoré malo loptičku.</li>



                <li>Ak hráč družstva, ktoré má na hracej ploche rovnaký alebo väčší počet hráčov ako súper vystrelí alebo usmerní loptičku zo svojej polovice hracej plochy za bránkovú čiaru súpera hra sa preruší a signalizuje sa zakázané uvoľnenie hneď po prejdení loptičky za bránkovú čiaru(okrem priestoru bránky).</li>



                <li>Vhadzovanie sa prevedie na tom koncovom bode na vhadzovanie brániaceho sa družstva, ktorý je najbližšie k miestu posledného kontaktu s loptičkou.</li>



                <li>Zakázané uvoľnenie nie je ak:</li>
            </ol>



            <p>- loptička vnikne do bránky - uzná sa gól,<br />- loptička prejde cez bránkovisko,<br />- ak družstvo, ktoré sa previnilo je v momente vystrelenia loptičky oslabené,<br />- loptička predtým ako prejde cez bránkovú čiaru sa dotkne akejkoľvek časti súperovho hráča, vrátane brankára,<br />- loptička prejde za bránkovú čiaru priamo od hráča, ktorý bol na vhadzovaní,<br />- podľa mienky čiarového rozhodcu, ktorýkoľvek hráč druhého družstva, okrem brankára&nbsp;mohol s loptičkou hrať predtým ako prešla cez bránkovú čiaru.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">9.</mark></strong>&nbsp;Postavenie&nbsp;mimo hry:</p>



            <p><strong>a. </strong>Toto pravidlo sa neuplatňuje. </p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">10.</mark></strong> Zdržiavanie hry:&nbsp;stanovuje, že ak brankár alebo hráč z tímu zdržiava hru úmyselným posunutím bránky z jej základnej polohy alebo iným spôsobom, ktorý rozhodca určí ako úmyselný. Mužstvo, ktoré sa previnilo bude potrestané menším trestom.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">11.</mark></strong> Úmyselné vyhodenie&nbsp;lopty brankárom za oplotenie ihriska sa považuje za zdržiavanie hry.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">12.</mark></strong> Gól:&nbsp;je vtedy, keď sa lopta dovoleným spôsobom dostane za bránovú čiaru. Ak útočiaci hráč úmyselne kopne loptičku a tá sa odrazí do bránky gól sa neuzná.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">13. </mark></strong>Technický gól:&nbsp;rozhodca uzná gól vtedy, ak je odvolaný brankár&nbsp;a útočiaci hráč nemá pred sebou nijakého protihráča a má možnosť dosiahnuť gól do prázdnej bránky a v tejto situácii mu protihráč hodí hokejku, alebo inak znemožní dať gól (faulom). Gól sa prizná v prospech útočiaceho tímu. Ak útočiaci hráč stojí v bránkovisku a nijako fyzicky neprekáža brankárovi, alebo mu nebráni vo výhľade a lopta sa dostane do bránky dovoleným spôsobom gól platí. Avšak útočiaci hráč nesmie stáť v bránkovisku viac ako 3 sekundy.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">14.</mark></strong> Ak je brankár&nbsp;v bránke a medzi útočiacim hráčom, ktorý má loptu pod kontrolou nie&nbsp;je nijaký protihráč potom rozhodca za hodenie hokejky alebo faul, nariadi trestné strieľanie v prospech tímu, ktorý sa neprevinil.</p>



            <p><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent"><strong>15.</strong></mark> Trestné strieľanie:&nbsp;Rozhodca nariadi trestné strieľanie: Podľa pravidla 14.</p>



            <ol></ol>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">16.</mark></strong> Nadávanie&nbsp;a zlé zachádzanie s činovníkmi stretnutia a iné hrubé správanie:</p>



            <p>Ak protestuje potrestaný hráč bez fyzických gest a bez použitia vulgárnych výrazov, rozhodca môže uložiť menší trest za nešportové správanie. Ak použije hráč neprimerané gestá či výrazy uloží sa osobný trest. Keď protestuje hráč, ktorý nebol potrestaný uloží sa osobný trest. Keď hráč úmyselne provokuje súpera rozhodca môže uložiť menší trest za nešportové správanie.</p>



            <p>Hráč, ktorý sa dopustí niektorého z nasledujúcich priestupkov bude potrestaný osobným&nbsp;trestom:</p>



            <ol>
                <li>Po uložení trestu nejde priamo a ihneď na trestnú lavicu, a nezaujme na nej svoje &nbsp;miesto,&nbsp;alebo nejde ihneď do šatne po uložení trestu do konca zápasu (DKZ).</li>



                <li>Mimo hracej plochy používa hrubé, vulgárne alebo urážlivé slová proti komukoľvek, alebo&nbsp;vysloví meno ktoréhokoľvek činovníka stretnutia v spojení s takýmito poznámkami.</li>



                <li>Mimo hracej plochy bráni akýmkoľvek spôsobom, ktorémukoľvek činovníkovi stretnutia,&nbsp;vrátane rozhodcov, časomeračov vo výkone ich povinností.</li>



                <li>Hovorí hrubo, vulgárne alebo urážlivo s kýmkoľvek na hracej ploche, alebo kdekoľvek na&nbsp;ihrisku pred, v priebehu alebo po zápase.</li>



                <li>Aj napriek upozorneniu rozhodcov zotrváva v spôsobe jednania (vrátane vyhrážok, urážok, posunkov alebo podobných činov), ktoré má za účel vyprovokovať súpera k priestupku.</li>
            </ol>



            <p>Hráč, ktorý sa dopustí niektorého z nasledujúcich priestupkov bude potrestaný podľa&nbsp;uváženia rozhodcu osobným trestom alebo osobným trestom do konca stretnutia, alebo&nbsp;disciplinárnym trestom ak:</p>



            <p>- Zotrváva v spôsobe jednania, za ktorý bol predtým potrestaný osobným trestom.<br />- Používa vulgárne posunky na hracej ploche kedykoľvek na ihrisku pred, počas alebo po&nbsp;stretnutí.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">17. </mark></strong>Tresty:</p>



            <ol>
                <li>Menší trest (MT, 1 minúta). Do kategórie menších trestov patrí: seknutie, podrazenie,&nbsp;držanie protihráča, hákovanie, krosček, nebezpečná hra so zdvihnutou hokejkou, nedovolené bránenie v hre, hrubosť, provokácie, zdržiavanie hry, príliš veľa hráčov na hracej ploche, držanie hokejky, pád pod nohy, vykopnutie loptičky brankárom, pokus o&nbsp;bodnutie hokejkou(1+1+5),</li>



                <li>Väčší trest (VT, 3 minúty). Do kategórie väčších trestov patrí: hrubosť, vrazenie na&nbsp;mantinel, faul lakťom, napadnutie, bodnutie hokejkou(3+DKZ), hodenie hokejky(3+DKZ),</li>



                <li>Osobné tresty (OT, 5 minút). Do kategórie osobných trestov patrí: napadnutie zozadu,&nbsp;napadnutie hlavy a krku, nešportové správanie, opakované provokácie, protesty, údery päsťou alebo hrubá hra,</li>



                <li>Osobný trest do konca zápasu(DKZ): zákerný faul, faul kolenom</li>



                <li>Trest v hre (TH): pri zranení protihráča, úder hlavou, kopnutie, nadmerná hrubosť,</li>



                <li>Trestné strieľanie (TS): viď. bod 16.</li>
            </ol>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">18. </mark></strong>Napadnutie zozadu, je taký zákrok na zadnú časť tela hráča, ktorý nemôže predvídať, a preto nie je schopný brániť sa. (vyšší až osobný trest)</p>



            <p><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent"><strong>19. </strong></mark>Brankár&nbsp;nesmie zaľahnúť loptičku za bránkovou čiarou iba ak sa dotýka časťou výstroje územia pred bránkou. Brankár môže prerušiť hru prikrytím loptičky v území od bránkovej čiary až po kruhy na vhadzovanie v jeho obrannom pásme.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">20. </mark></strong>Brankár musí mať počas zápasu kompletnú výstroj. Hráč musí mať rukavice, chrániče holení a kolien, alebo aspoň futbalové chrániče + chránič kolena. Holenné chrániče a chrániče kolien musia byť zakryté&nbsp; štucňami alebo pod nohavicami. Každý hráč je povinný mať počas zápasov na hlave hokejbalovú alebo hokejovú prilbu s celotvárovou maskou (mriežka). Povinnosť hráčov nosiť prilbu s celotvárovou maskou (mriežkou) sa týka kategórie U15 a U19. Odporúčajú sa však aj ďalšie časti hokejbalovej výstroje hráčov (suspenzor, hokejbalové nohavice, hokejbalové chrániče lakťov, chrániče zubov). Ide v prvom rade o zdravie každého hráča.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">21. </mark></strong>Zovretie lopty:&nbsp;hráč nesmie zovrieť loptičku.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">22. </mark></strong>O&nbsp;výklade&nbsp;pravidiel určuje:</p>



            <ol>
                <li>V zápase rozhodca (ak je to nevyhnutné v pokračovaní zápasu)</li>



                <li>V zložitejších prípadoch komisia rozhodcov.</li>
            </ol>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">23. </mark></strong>Rozhodca píska&nbsp;podľa svojho najlepšieho svedomia a vedomia, preto sa o jeho&nbsp;zákrokoch nediskutuje. Kvôli lepšiemu prehľadu o hre sa odporúča, aby rozhodovali dvaja&nbsp;rozhodcovia.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">24</mark></strong>. Hra s loptičkou so zdvihnutou hokejkou :&nbsp;Zastavenie alebo zrazenie&nbsp;loptičky hokejkou nad úrovňou ramien je zakázané a hra sa nepreruší iba vtedy, ak:</p>



            <ol>
                <li>Loptička je zrazená k protihráčovi, a potom hlavný rozhodca musí použiť signál nie,</li>



                <li>Hráč brániaceho sa družstva zrazí loptičku do vlastnej bránky, a potom sa uzná gól.</li>



                <li>Ak s loptičkou hral útočiaci hráč s hokejkou zdvihnutou nad úroveň svojich ramien vo&nbsp;svojom útočnom pásme, hra sa preruší a vhadzovanie sa prevedie na najbližšom bode&nbsp;na vhadzovanie v strednom pásme.</li>



                <li>Ak s loptičkou hral hráč s hokejkou zdvihnutou nad úroveň svojich ramien vo svojom&nbsp;obrannom alebo strednom pásme, hra sa preruší a vhadzovanie sa prevedie na mieste,&nbsp;na ktorom sa stal priestupok. Ak by však družstvo, ktoré sa previnilo malo získať územnú&nbsp;výhodu, vhadzovanie sa prevedie na mieste, na ktorom bola prerušená hra.</li>



                <li>Ak sa útočiaci hráč dotkol loptičky hokejkou, ktorú mal nad úrovňou hornej žŕdky&nbsp;bránky, gól nemôže byť uznaný.</li>
            </ol>



            <p><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent"><strong>25.</strong> </mark>Ak brankárovi&nbsp;počas hry spadne prilba alebo celotvárová maska, hlavný rozhodca&nbsp;musí prerušiť hru. Ak počas hry zasiahne prudká strela brankára do celotvárovej masky,&nbsp;hlavný rozhodca musí prerušiť hru.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">26.</mark></strong> Oddychový čas :&nbsp;Každé družstvo si môže počas riadneho hracieho času alebo počas&nbsp;predĺženia vyžiadať jeden 30-sekundový oddychový čas. O oddychový čas môže počas&nbsp;prerušenia hry požiadať hlavného rozhodcu ľubovoľný hráč určený trénerom. Hlavný rozhodca musí oznámiť oddychový čas zapisovateľovi. Hráči a brankári obidvoch družstiev, okrem potrestaných hráčov, môžu prísť k svojim hráčskym laviciam. Družstvá si nemôžu vziať oddychový čas počas toho istého prerušenia hry. Družstvá si môžu vyžiadať oddychový čas len dovtedy, kým rozhodca zdvihnutou rukou signalizuje ukončenie striedania hosťujúceho družstva. Keď rozhodca ruku pripaží, oddychový čas si už žiadne družstvo vyžiadať nesmie.</p>



            <p><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent"><strong>27.</strong></mark> Spôsob vykonania vhadzovania :&nbsp;Hlavný alebo čiarový rozhodca vhodí loptičku&nbsp;medzi hokejky dvoch hráčov, ktorí sú na vhadzovaní. Hráči stoja tvárou k súperovmu koncu ihriska, približne vo vzdialenosti dĺžky jednej hokejky od seba a čepele ich hokejok sa dotýkajú hracej plochy na nevyplnenej časti bodu na vhadzovanie. Hráč útočiaceho družstva na svojej útočnej polovici musí položiť svoju hokejku na hraciu plochu ako prvý, ihneď nasledovaný hráčom brániaceho sa družstva. Ak sa vhadzuje na strednom bode na&nbsp;vhadzovanie alebo na strednej čiare, hokejku musí položiť na hraciu plochu ako prvý hráč&nbsp;hosťujúceho družstva.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">28.</mark></strong> Zastavenie/ prihrávka loptičky rukou: Hráč smie zastaviť alebo zraziť loptičku vo&nbsp;vzduchu otvorenou rukou alebo rukou loptičku posunúť po hracej ploche, okrem prípadu, keď podľa mienky hlavného rozhodcu hráč úmyselne usmernil loptičku svojmu spoluhráčovi:</p>



            <ol>
                <li>Ak spoluhráč tohto hráča získal loptičku v strednom pásme hlavný rozhodca preruší hru a vhadzovanie sa prevedie na mieste, kde bola prerušená hra.</li>



                <li>Ak spoluhráč tohto hráča získal loptičku vo svojom obrannom pásme, hlavný rozhodca&nbsp;hru nepreruší za predpokladu, že akcia prihrávky rukou sa skončí skôr ako hráč a loptička&nbsp;opustia svoju polovicu. Ak však loptičku rukou prihrá hráč zo stredného pásma spoluhráčovi vo svojom obrannom pásme, hlavný rozhodca preruší hru a vhadzovanie sa prevedie na mieste, kde bola prerušená hra.(Prihrávka rukou je povolená po stredovú čiaru, no musí smerovať z obranného pásma).</li>
            </ol>



            <p>Ak spoluhráč tohto hráča získal loptičku vo svojom útočnom pásme, hlavný rozhodca&nbsp;preruší hru a vhadzovanie sa prevedie v strednom pásme na bode na vhadzovanie pri&nbsp;jeho útočnom pásme.</p>



            <p>Gól sa nesmie uznať, ak loptičku zrazil rukou útočiaci hráč aj keby sa do bránky odrazila,&nbsp;od ktoréhokoľvek hráča alebo jeho hokejky, alebo topánky, od brankára, alebo rozhodcu.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">29. </mark></strong>Kopnutie loptičky :&nbsp;Kopať do loptičky sa smie vo všetkých pásmach, ale gól&nbsp;nemôže byť uznaný, ak loptičku kopol do bránky útočiaci hráč. Gól môže byť uznaný iba v&nbsp;prípade, ak sa kopnutá loptička odrazí do bránky od hokejky útočiaceho hráča.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">30.</mark></strong> Signalizovanie trestov.&nbsp;Pri porušení pravidiel, za ktoré sa ukladá trest sa&nbsp;postupuje takto:</p>



            <ol>
                <li>Ak má loptičku družstvo hráča, ktorý sa previnil, hlavný rozhodca okamžite zapíska a&nbsp;uloží trest.</li>



                <li>Ak družstvo hráča, ktorý sa previnil nemá loptičku hlavný rozhodca vzpaží ruku, čím&nbsp;avizuje, že uloží trest a potom po dokončení akcie družstva, ktoré malo loptičku, zapíska a&nbsp;uloží trest.</li>



                <li>Ak po tom ako hlavný rozhodca vzpažil ruku dosiahne gól družstvo, ktoré sa previnilo&nbsp;gól sa neuzná a trest sa uloží obvyklým spôsobom.</li>



                <li>Ak po tom ako hlavný rozhodca vzpažil ruku dosiahne gól družstvo, ktoré sa neprevinilo&nbsp;gól sa uzná a prvý menší trest sa neuloží. Všetky ďalšie tresty sa uložia. Ak družstvo, ktoré sa previnilo už bolo oslabené, avizovaný menší trest sa zruší, ale všetky tresty odpykávané na trestnej lavici zostávajú.</li>



                <li>Vhadzovanie po prerušení hry sa vždy prevedie v útočnom pasme družstva, ktoré má hrať presilovku. Ak počas avizovania trestu hráčovi družstva, ktoré nemá loptičku spôsobí družstvo, ktoré má loptičku zakázané uvoľnenie alebo vystrelí loptičku zo svojho obranného pásma tak, že sa loptička dostane mimo hracej plochy alebo sa s ňou nedá hrať, vhadzovanie sa prevedie v bode na vhadzovanie v strednom pásme pri modrej čiare obranného pásma tohto družstva.</li>
            </ol>



            <p>Napadnutie je zákrok hráča, ktorý urobí viac ako dva kroky alebo tempá, aby vyvolal telesný kontakt s protihráčom. Hráč, ktorý po zapískaní vyvolá fyzický kontakt s protihráčom a&nbsp;ak podľa mienky hlavného rozhodcu, mal hráč po zapískaní dostatok času predísť takému kontaktu, hlavný rozhodca mu uloží trest za napadnutie.</p>



            <p>Brankár, aj keď je mimo bránkoviska nie je objekt, ktorý možno napádať. Ak má&nbsp;protihráč zbytočný kontakt s brankárom, hlavný rozhodca takému hráčovi uloží trest za&nbsp;nedovolené bránenie v hre alebo napadnutie.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">31.</mark></strong> Padnutie hráča&nbsp;na loptičku. Hráčovi, okrem brankára, ktorý úmyselne padne na&nbsp;loptičku, drží ju alebo ju stiahne pod svoje telo bude uložený&nbsp;Menší trest&nbsp;(1 min.).&nbsp;Keď brániaci hráč, okrem brankára, úmyselne padne na loptičku, drží ju alebo ju stiahne pod svoje telo, keď je v bránkovisku jeho družstva, hlavný rozhodca družstvu, ktoré sa&nbsp;neprevinilo, prizná&nbsp;Trestné strieľanie&nbsp;(TS).Keď bol súperov brankár odvolaný z hracej&nbsp;plochy a hráč úmyselne padne na loptičku, drží ju alebo ju stiahne pod svoje telo, keď je v&nbsp;bránkovisku jeho družstva, hlavný rozhodca družstvu, ktoré sa neprevinilo prizná&nbsp;Gól.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">32.</mark></strong> Konanie kapitána&nbsp;a náhradného kapitána. Kapitánovi alebo náhradnému kapitánovi, ktorý protestuje proti trestu bude uložený&nbsp;menší trest.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">33. </mark></strong>Príliš veľa hráčov&nbsp;na hracej ploche. Keď v priebehu hry má družstvo na hracej ploche viac hráčov ako je oprávnené ich mať, bude mu uložený&nbsp;menší trest&nbsp;pre hráčsku lavicu.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">34.</mark></strong> Nedodržanie&nbsp;postupu pri striedaní. Keď sa družstvo pokúsi striedať hráča(ov) po&nbsp;vymedzenom časovom intervale, hlavný rozhodca pošle hráča(ov) späť na hráčsku lavicu a&nbsp;družstvo NAPOMENIE. Za ďalšie porušenie tohto postupu kedykoľvek počas stretnutia musí byť uložený&nbsp;menší trest&nbsp;pre hráčsku lavicu.</p>



            <p><strong><mark style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }} className="  text-accent">35.</mark></strong> Brankár opustí&nbsp;bránkovisko počas šarvátky. Brankárovi, ktorý počas šarvátky opustí&nbsp;bezprostredné okolie svojho bránkoviska bude uložený&nbsp;menší trest.</p>
        </div>
    )
}